import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class PageLoader extends React.Component {
    state = {}

    render = () => {
        return (
            <div id="app-wrapper">
                <div id="content">
                    <div className="box m-auto text-center">
                        <span className="large-status-icon">
                            <FontAwesomeIcon icon="spinner" spin />
                            <h1 className="m-5 text-center">Loading content...</h1>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageLoader
