import { fromJS } from 'immutable'
import * as helpers from './helpers'

const INITIAL_STATE = fromJS([])

const genericReducer = (actionType, structure = 'LIST', initialState = INITIAL_STATE, defaultKey = 'id') => {
    return (state = initialState, action) => {
        let objectIndex = -1
        let newState = state

        switch (action.type) {
        case `SAVE_${actionType}`:
            // make a copy so we can remove properties...
            let dataObj = { ...action.data }
            if ('_key' in dataObj) {
                delete dataObj._key
            }
            if ('_related' in dataObj) {
                delete dataObj._related
            }
            if (structure === 'OBJECT') {
                return state.merge(dataObj)
            }
            objectIndex = helpers.getObjectIndex(state, dataObj[defaultKey], defaultKey)
            if (objectIndex === -1) {
                return helpers.addObject(state, dataObj)
            }
            return helpers.updateObject(state, dataObj, objectIndex)
        case `SAVE_${actionType}_LIST`:
            action.data.forEach(model => {
                let modelObj = { ...model }
                if ('_key' in modelObj) {
                    delete modelObj._key
                }
                if ('_related' in modelObj) {
                    delete modelObj._related
                }
                objectIndex = helpers.getObjectIndex(newState, model[defaultKey], defaultKey)
                if (objectIndex === -1) {
                    newState = helpers.addObject(newState, modelObj)
                } else {
                    newState = helpers.updateObject(newState, modelObj, objectIndex)
                }
            })
            return newState
        case `REMOVE_${actionType}`:
            if (structure === 'OBJECT') {
                return initialState
            }
            return state.filter(obj => obj.get(defaultKey) !== action.data[defaultKey])
        case `BULK_REMOVE_${actionType}`:
            if (structure === 'OBJECT') {
                return initialState
            }
            console.log(action.data[action.data.filterKey])
            return state.filter(obj => obj.get(action.data.filterKey) !== action.data[action.data.filterKey])
        case `KILL_PARTIAL_${actionType}`:
        case `KILL_STATE`:
            return initialState
        default:
            return state
        }
    }
}

export default genericReducer
