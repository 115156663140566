import { fromJS } from 'immutable'

const INITIAL_STATE = fromJS({})

const pagerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SAVE_PAGINATOR':
        return state.set(action.key, fromJS(action.data))
    case 'RESET_PAGINATOR':
        return state.set(action.key, fromJS({}))
    case 'KILL_STATE':
        return INITIAL_STATE
    default:
        return state
    }
}

export default pagerReducer
