import immutable from 'immutable'

const INITIAL_STATE = immutable.fromJS({
    ndaUiStyle: 'CARDS',
    standardUiStyle: 'CARDS'
})

const uiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SAVE_NDA_VIEW_STYLE':
        return state
            .set('ndaUiStyle', action.style)
    case 'SAVE_STANDARD_VIEW_STYLE':
        return state
            .set('standardUiStyle', action.style)
    case 'SET_ACTIVE_USER_TOKEN':
        return state
            .set('userTokenActive', true)
    case 'KILL_STATE':
        return immutable.fromJS({})
    default:
        return state
    }
}

export default uiReducer
