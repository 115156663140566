import { combineReducers } from 'redux-immutable'
import { fromJS } from 'immutable'

import apiCacheReducer from './api-cache'
import authCodeReducer from './auth-code'
import authReducer from './auth'
import extractionControlReducer from './extraction-controls'
import formReducer from './form'
import genericReducer from './generic'
import pagerReducer from './pager'
import uiReducer from './ui'

// import * as INITIAL_STATES from '../constants/initial-reducer-states'

const BLANK_OBJ = fromJS({})
const BLANK_LIST = fromJS([])

const indexReducer = combineReducers({
    account: genericReducer('ACCOUNT', 'OBJECT', BLANK_OBJ),
    accountEngagementExtraFields: genericReducer('ACCOUNT_ENGAGEMENT_EXTRA_FIELDS', 'LIST', BLANK_LIST),
    accountInfo: genericReducer('ACCOUNT_INFO', 'OBJECT', BLANK_OBJ),
    accountType: genericReducer('ACCOUNT_TYPE', 'OBJECT', BLANK_OBJ),
    adminAccountInfo: genericReducer('ADMIN_ACCOUNT_INFO', 'LIST', BLANK_LIST),
    adminAccountType: genericReducer('ADMIN_ACCOUNT_TYPE', 'LIST', BLANK_LIST),
    adminAccounts: genericReducer('ADMIN_ACCOUNT', 'LIST', BLANK_LIST),
    adminConcepts: genericReducer('ADMIN_ML_CONCEPTS', 'LIST', BLANK_LIST),
    adminConceptTerms: genericReducer('ADMIN_ML_CONCEPT_TERMS', 'LIST', BLANK_LIST),
    adminContactForms: genericReducer('ADMIN_CONTACT_FORMS', 'LIST', BLANK_LIST),
    adminDashboard: genericReducer('ADMIN_DASHBOARD', 'LIST', BLANK_LIST, 'day'),
    adminDemoRequests: genericReducer('ADMIN_DEMO_REQUESTS', 'LIST', BLANK_LIST),
    adminEngagements: genericReducer('ADMIN_ENGAGEMENTS', 'LIST', BLANK_LIST),
    adminEngagementDetails: genericReducer('ADMIN_ENGAGEMENT_DETAILS', 'LIST', BLANK_LIST),
    adminEngagementFiles: genericReducer('ADMIN_ENGAGEMENT_FILES', 'LIST', BLANK_LIST),
    adminEngagementSignatures: genericReducer('ADMIN_ENGAGEMENT_SIGNATURES', 'LIST', BLANK_LIST),
    adminMLClassifications: genericReducer('ADMIN_ML_CLASSIFICATIONS', 'LIST', BLANK_LIST),
    adminMLDryRuns: genericReducer('ADMIN_ML_DRY_RUNS', 'LIST', BLANK_LIST),
    adminEmbeddings: genericReducer('ADMIN_ML_EMBEDDINGS', 'LIST', BLANK_LIST),
    adminExamples: genericReducer('ADMIN_ML_EXAMPLES', 'LIST', BLANK_LIST),
    adminExtractions: genericReducer('ADMIN_ML_EXTRACTIONS', 'LIST', BLANK_LIST),
    adminFineTunes: genericReducer('ADMIN_ML_FINE_TUNES', 'LIST', BLANK_LIST),
    adminFoundLabels: genericReducer('ADMIN_ML_FOUND_LABELS', 'LIST', BLANK_LIST),
    adminLabels: genericReducer('ADMIN_ML_LABELS', 'LIST', BLANK_LIST),
    adminMLAnalysis: genericReducer('ADMIN_ML_ANALYSIS', 'LIST', BLANK_LIST),
    adminMLChatIssues: genericReducer('ADMIN_ML_CHAT_ISSUES', BLANK_LIST),
    adminMLChatProcesses: genericReducer('ADMIN_CHAT_PROCESSES', BLANK_LIST),
    adminMLChatProvisions: genericReducer('ADMIN_ML_CHAT_PROVISIONS', BLANK_LIST),
    adminMLChatResponses: genericReducer('ADMIN_ML_CHAT_RESPONSES', BLANK_LIST),
    adminMLChatStandards: genericReducer('ADMIN_ML_CHAT_STANDARDS', BLANK_LIST),
    adminMLChatTemplates: genericReducer('ADMIN_ML_CHAT_TEMPLATES', BLANK_LIST),
    adminMLChatWorkflows: genericReducer('ADMIN_CHAT_WORKFLOWS', BLANK_LIST),
    adminMLFiles: genericReducer('ADMIN_ML_FILES', 'LIST', BLANK_LIST),
    adminModels: genericReducer('ADMIN_ML_MODELS', 'LIST', BLANK_LIST),
    adminOptions: genericReducer('ADMIN_OPTIONS', 'LIST', BLANK_LIST),
    adminOutbounds: genericReducer('ADMIN_OUTBOUND_CAMPAIGNS', 'LIST', BLANK_LIST),
    adminParserSettings: genericReducer('ADMIN_PARSER_SETTINGS', 'LIST', BLANK_LIST),
    adminParsingRequests: genericReducer('ADMIN_PARSING_REQUESTS', 'LIST', BLANK_LIST),
    adminParsingResponses: genericReducer('ADMIN_PARSING_RESPONSES', 'LIST', BLANK_LIST),
    adminProvisions: genericReducer('ADMIN_PROVISIONS', 'LIST', BLANK_LIST),
    adminPlayground: genericReducer('ADMIN_PLAYGROUND', 'LIST', BLANK_LIST),
    adminClassiciationPlayground: genericReducer('ADMIN_CLASSIFICATION_PLAYGROUND', 'LIST', BLANK_LIST),
    adminRoles: genericReducer('ADMIN_ROLE', 'LIST', BLANK_LIST),
    adminSampleDocs: genericReducer('ADMIN_SAMPLE_DOCS', 'LIST', BLANK_LIST),
    adminSections: genericReducer('ADMIN_SECTIONS', 'LIST', BLANK_LIST),
    adminStandards: genericReducer('ADMIN_STANDARDS', 'LIST', BLANK_LIST),
    adminStoplight: genericReducer('ADMIN_STOPLIGHTS', 'LIST', BLANK_LIST),
    adminStoplightEntries: genericReducer('ADMIN_STOPLIGHT_ENTRIES', 'LIST', BLANK_LIST),
    adminTaxonomies: genericReducer('ADMIN_TAXONOMIES', 'LIST', BLANK_LIST),
    adminUsers: genericReducer('ADMIN_USERS', 'LIST', BLANK_LIST),
    adminWordModels: genericReducer('ADMIN_WORD_MODELS', 'LIST', BLANK_LIST),
    answers: genericReducer('ANSWERS', 'LIST', BLANK_LIST),
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    chatProcesses: genericReducer('CHAT_PROCESSES', 'LIST', BLANK_LIST),
    chatProvisions: genericReducer('ML_CHAT_PROVISIONS', 'LIST', BLANK_LIST),
    chatStandards: genericReducer('ML_CHAT_STANDARDS', 'LIST', BLANK_LIST),
    configValues: genericReducer('CONFIG_VALUES', 'LIST', BLANK_LIST),
    engagements: genericReducer('ENGAGEMENTS', 'LIST', BLANK_LIST),
    engagementDetails: genericReducer('ENGAGEMENT_DETAILS', 'LIST', BLANK_LIST),
    engagementExtraFieldAnswers: genericReducer('ENGAGEMENT_EXTRA_FIELD_ANSWERS', 'LIST', BLANK_LIST),
    engagementFiles: genericReducer('ENGAGEMENT_FILES', 'LIST', BLANK_LIST),
    engagementLogs: genericReducer('ENGAGEMENT_LOGS', 'LIST', BLANK_LIST),
    engagementReports: genericReducer('ENGAGEMENT_REPORTS', 'LIST', BLANK_LIST),
    engagementSignatures: genericReducer('ENGAGEMENT_SIGNATURES', 'LIST', BLANK_LIST),
    extractionControls: extractionControlReducer,
    extractionSelections: genericReducer('EXTRACTION_SELECTIONS', 'LIST', BLANK_LIST),
    forms: formReducer,
    foundLabels: genericReducer('ML_FOUND_LABELS', 'LIST', BLANK_LIST),
    invitations: genericReducer('INVITATIONS', 'LIST', BLANK_LIST),
    jobBatches: genericReducer('JOB_BATCHES', 'LIST', BLANK_LIST),
    jobs: genericReducer('JOBS', 'LIST', BLANK_LIST),
    masterStandardFields: genericReducer('MASTER_STANDARD_FIELDS', 'LIST', BLANK_LIST),
    members: genericReducer('MEMBERS', 'LIST', BLANK_LIST),
    ndas: genericReducer('NDAS', 'LIST', BLANK_LIST),
    options: genericReducer('OPTIONS', 'LIST', BLANK_LIST),
    outboundCampaigns: genericReducer('OUTBOUND_CAMPAIGNS', 'LIST', BLANK_LIST),
    paginator: pagerReducer,
    parsingResponses: genericReducer('PARSING_RESPONSES', 'LIST', BLANK_LIST),
    permissions: genericReducer('PERMISSIONS', 'LIST', BLANK_LIST),
    provisions: genericReducer('PROVISIONS', 'LIST', BLANK_LIST),
    publicRoles: genericReducer('PUBLIC_ROLES', 'LIST', BLANK_LIST),
    role: genericReducer('ROLE', 'OBJECT', BLANK_OBJ),
    sections: genericReducer('SECTIONS', 'LIST', BLANK_LIST),
    standards: genericReducer('STANDARDS', 'LIST', BLANK_LIST),
    standardFiles: genericReducer('STANDARD_FILES', 'LIST', BLANK_LIST),
    standardStatuses: genericReducer('STANDARD_STATUSES', 'LIST', BLANK_LIST),
    stoplights: genericReducer('STOPLIGHTS', 'LIST', BLANK_LIST),
    stoplightEntries: genericReducer('STOPLIGHT_ENTRIES', 'LIST', BLANK_LIST),
    stripeCards: genericReducer('STRIPE_CARDS', 'LIST', BLANK_LIST),
    stripeCustomer: genericReducer('STRIPE_CUSTOMER', 'OBJECT', BLANK_OBJ),
    stripeInvoices: genericReducer('STRIPE_INVOICES', 'LIST', BLANK_LIST),
    subscriptions: genericReducer('SUBSCRIPTIONS', 'OBJECT', BLANK_OBJ),
    tags: genericReducer('TAXONOMIES', 'LIST', BLANK_LIST),
    teams: genericReducer('TEAMS', 'LIST', BLANK_LIST),
    templateStandards: genericReducer('TEMPLATE_STANDARDS', 'LIST', BLANK_LIST),
    ui: uiReducer,
    user: genericReducer('USER', 'OBJECT', BLANK_OBJ),
    webSocketNotifications: genericReducer('WEB_SOCKET_NOTIFICATIONS', 'LIST', BLANK_LIST)
})

export default indexReducer
