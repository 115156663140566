import immutable from 'immutable'

const INITIAL_STATE = immutable.fromJS({
    scanType: 'SENTENCE',
    selectionActive: false
})

const uiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SAVE_ELEMENT_SCAN':
        return state
            .set('scanType', action.element)
    case 'SAVE_SELECTION_TEXT':
        return state
            .set('text', action.text)
            .set('selectionActive', true)
    case 'ADD_TO_SELECTION_TEXT':
        console.log('in here?')
        console.log(state)
        return state
            .set('text', state.get('text') + ' ' + action.text)
            .set('selectionActive', true)
    case 'CLEAR_SELECTION':
        return state
            .set('text', null)
            .set('selectionActive', false)
    case 'KILL_STATE':
        return immutable.fromJS({})
    default:
        return state
    }
}

export default uiReducer
