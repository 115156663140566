import React from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'

import { routes, apiResourceEndpoint } from '../../constants/routes'
import { fetchApiDataIfNeeded, callApi } from '../../actions/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouterProps } from '../utils/with-router-props'

class AuthRouter extends React.Component {
    state = {
        readyToRoute: false,
        onboardStatus: '',
        stripeRequest: false,
        hasTimedOut: false
    }

    componentDidMount = () => {
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('users/load-details', 'LIST')))
    }

    runTimer = setTimeout(() => {
        this.setState({ hasTimedOut: true })
    }, 5000)

    componentWillUnmount = () => {
        clearTimeout(this.runTimer)
    }

    componentDidUpdate = (prevProps) => {
        const { auth, role, user, account } = this.props
        if (auth && auth.has('authEmail')) {
            if (user && user.has('id')) {
                const userId = user.get('id')
                if (userId === -1) {
                    this.setState({ onboardStatus: 'CREATE_USER' })
                } else if (role && role.has('name')) {
                    if (user.has('accountId') && user.get('accountId') > 0) {
                        if (account && account.get('stripeCustomer')) {
                            if (account.hasIn(['_computed', 'subscriptionStatusValue']) && account.getIn(['_computed', 'subscriptionStatusValue']) === 'ACTIVE') {
                                this.setState({ readyToRoute: true })
                            } else {
                                this.setState({ onboardStatus: 'CREATE_SUBSCRIPTION' })
                            }
                        } else if (!this.state.stripeRequest) {
                            this.setState({ stripeRequest: true })
                            this.props.dispatch(callApi(apiResourceEndpoint('accounts', 'CREATE', null, 'stripe'), {}, -1))
                        }
                    } else {
                        this.setState({ onboardStatus: 'CREATE_ACCOUNT' })
                    }
                }
            }
        }
    }

    render = () => {
        const { location } = this.props
        if (this.state.readyToRoute) {
            if (location.state && location.state.from.pathname) {
                return <Navigate to={location.state.from.pathname} />
            }
            return <Navigate to={routes.ndaIndex.path} />
        }
        switch (this.state.onboardStatus) {
        case 'CREATE_USER':
            return <Navigate to={routes.authCreateUser.path} />
        case 'CREATE_ACCOUNT':
            return <Navigate to={routes.authCreateAccount.path} />
        case 'CREATE_SUBSCRIPTION':
            return <Navigate to={routes.authCreateSubscription.path} />
        default:
            return (<>
                <div id="app-wrapper">
                    <div id="content">
                        <div className="container">
                            <div className="row mt-4">
                                <div className="box m-auto text-center">
                                    <span className="large-status-icon">
                                        {this.state.hasTimedOut ? (
                                            <>
                                                <h1 className="m-5 text-center">Something Went Wrong</h1>
                                                <p className="m-5 text-center">
                                                    <Link to={routes.index.path} className="btn blue">Back to Homepage</Link>
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <h1 className="m-5 text-center">Heading to Your Account...</h1>
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.get('account'),
        auth: state.get('auth'),
        role: state.get('role'),
        user: state.get('user')
    }
}

export default withRouterProps(connect(mapStateToProps)(AuthRouter))
