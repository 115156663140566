import React from 'react'
import { Navigate } from 'react-router-dom'
import { routes } from '../../constants/routes'

export function AppRoute({ children, auth, location }) {
    let isAuthenticated = (!auth.isEmpty() && !auth.get('isAnonymous')) || false
    return isAuthenticated ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}

// TODO: make the auth check more restrictive
export function AdminRoute({ children, auth, role, location }) {
    let isAuthenticated = (!auth.isEmpty() && role && role.get('name') === 'Super Admin') || false
    return isAuthenticated ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}
