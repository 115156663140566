import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import ndaokData from '../reducers/index'

export default function configureStore(initialState) {
    return createStore(
        ndaokData,
        initialState,
        compose(
            applyMiddleware(thunk)
        )
    )
}
