import React from 'react'
import { createBrowserHistory } from 'history'
import { useLocation, useNavigate, useParams, matchRoutes } from 'react-router-dom'
import { routes } from '../../constants/routes'

export const withRouterProps = (Component) => {
    const Wrapper = (props) => {
        const history = createBrowserHistory()
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()
        let myRoutes = Object.keys(routes).map(r => ({ path: routes[r].path }))
        let matches = matchRoutes(myRoutes, location.pathname)

        let locationParts = matches[0].route.path.split('/').filter(e => e)
        let pathNameParts = location.pathname.split('/').filter(e => e)
        let routeString = '/'
        let pathString = '/'
        let crumbs = []
        locationParts.forEach((loc, idx) => {
            routeString = routeString + loc + '/'
            pathString = pathString + pathNameParts[idx] + '/'
            Object.keys(routes).forEach(r => {
                if (routes[r].path === routeString) {
                    let crumbObj = { path: pathString, label: routes[r].label }
                    // build out the "View" specific paramters
                    if ('reducerKey' in routes[r]) {
                        crumbObj.reducerKey = routes[r].reducerKey
                    }
                    if ('pathId' in routes[r]) {
                        crumbObj.pathId = routes[r].pathId
                    }
                    if ('objField' in routes[r]) {
                        crumbObj.objField = routes[r].objField
                    }
                    if ('objId' in routes[r]) {
                        crumbObj.objId = routes[r].objId
                    }
                    crumbs.push(crumbObj)
                }
            })
        })

        let propParams = { ...params, matchedPath: matches[0].route.path }

        return (
            <Component
                history={history}
                location={location}
                navigate={navigate}
                params={propParams}
                crumbs={ crumbs }
                {...props}
            />
        )
    }
    return Wrapper
}
