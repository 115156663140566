import React from 'react'
import { connect } from 'react-redux'

import { clearApiFetch } from '../../actions/api'

import '../../assets/scss/loader.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ApiLoader extends React.Component {
    state = {

    }

    renderApiAlert = () => {
        const { erroredRoutes } = this.props
        if (erroredRoutes.size === 0) {
            return null
        }
        let allRoutes = []
        erroredRoutes.forEach((route, idx) => {
            allRoutes.push(
                <p key={`error-${idx}`}>
                    <FontAwesomeIcon icon="times" onClick={() => this.props.dispatch(clearApiFetch(route.get('url')))} />
                    We encountered an error. {route.get('error')}
                </p>
            )
        })
        return <div className="loader-errors">{allRoutes}</div>
    }

    renderApiLoader = () => {
        const { loadingRoutes } = this.props
        if (loadingRoutes.size === 0) {
            return null
        }
        let allRoutes = []
        loadingRoutes.forEach((route, idx) => {
            allRoutes.push(
                <p key={`loader-${idx}`}>
                    <FontAwesomeIcon icon="spinner" spin /> Fetching {route.get('url')}
                </p>
            )
        })
        return (
            <div className="loader-status">
                <div>
                    Fetching <FontAwesomeIcon icon="spinner" spin /> {loadingRoutes.size} resource{loadingRoutes.size === 1 ? '' : 's'}
                </div>
            </div>
        )
        // return <div className="loader-status">{allRoutes}</div>
    }

    render = () => {
        return (
            <div className="loader-alerts">
                {this.renderApiAlert()}
                {this.renderApiLoader()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const apiCaches = state.get('apiCaches')
    return {
        loadingRoutes: apiCaches.filter(cache => cache.get('isFetching') === true),
        erroredRoutes: apiCaches.filter(cache => cache.get('hasError') === true)
    }
}

export default connect(mapStateToProps)(ApiLoader)
