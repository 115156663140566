import React from 'react'
import { connect } from 'react-redux'

import { handleSignOut } from '../../actions/auth'

class AuthTokenBand extends React.Component {
    state = {}

    render = () => {
        if (this.props.ui.get('userTokenActive')) {
            return (
                <div className="auth-token-alert">
                    <span className="message">
                        You are currently logged in as a different user. Remember to logout.
                    </span>
                    <span className="btn blue mr-0" onClick={() => this.props.dispatch(handleSignOut())}>Logout</span>
                </div>
            )
        }
        return null
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.get('ui')
    }
}

export default connect(mapStateToProps)(AuthTokenBand)
