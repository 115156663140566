export const setNdaViewStyle = (style) => {
    return {
        type: 'SAVE_NDA_VIEW_STYLE',
        style
    }
}

export const setStandardViewStyle = (style) => {
    return {
        type: 'SAVE_STANDARD_VIEW_STYLE',
        style
    }
}

export const setActiveUserToken = () => {
    return {
        type: 'SET_ACTIVE_USER_TOKEN'
    }
}
