import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { fromJS } from 'immutable'
import configureStore from './store/configure-store'

import { initFirebase } from './firebase/firebase'

import './assets/scss/main.scss'
import App from './components/app'

import * as serviceWorker from './serviceWorker'

const firebaseApp = initFirebase()


let store = configureStore(fromJS({}))

render(
    <Provider store={store}>
        <BrowserRouter>
            <App firebaseApp={firebaseApp} />
        </BrowserRouter>
    </Provider>, document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
